.mobile,
.tablet {
  &.menu-visible{
    nav {
      left: 0;
      transition: left 0.2s ease-out;
    }
  }
  nav {
    background: $footerTopBgColor;
    position: fixed;
    top: 0;
    left: -100%;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 260px;
    z-index: 1;
    text-align: left;
    transition: transform 0s ease-out, visibility 0s;
    transform: translate3d(0, 0, 0);
    -webkit-overflow-scrolling: touch;

    &.show-menu {
      display: block !important;
      height: calc(100vh - 3em);
      transform: translate3d(0, 0, 0) !important;
      transition: transform 0.4s ease-out;
      visibility: visible !important;
    }

    .arrow {
      height: 38px;
      outline: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 38px;
      transition: transform 0.3s ease;
      transform: translate3d(0,0,0);
      -webkit-tap-highlight-color: $webkitHighlightTapColor;
      will-change: transform;
      z-index: 10;

      &:before {
        @include icon(12, 1) {
          position: absolute;
          margin-left: -7px;
          margin-top: -4px;
          height: 8px;
          width: 14px;
          left: 50%;
          top: 50%;
          transition: transform 0.3s ease;
          will-change: transform;
        }
      }
    }


    .menu_level_1 {
      display: block;
      padding: 0;

      & > li {
        display: block;
        width: auto;

        &.selected {

          & > .mob-icon {
            color: #222 !important;
          }

          &.subcat {
           .menu_level_2 {
            display: block;
          }
        }
      }

      &.subcat {
        & > a {
          width: calc(100% - 38px);
        }
      }

      &.open {
        .menu_level_2 {

        }

        .arrow {
          transform: translate3d(0,0,0);
          transition: transform 0.3s ease;



          &.rotate {

            &:before {
              transform: rotate(180deg);
              transition: transform 0.3s ease;
            }

          }
        }
      }

      & > a {
        color: $textColor;
        padding: 0.5em 1em 0.5em 1.5em;
        font-size: 11px;
        text-align: left;
      }
      &.selected {
        & > a {
          color: $white;
        }
      }

      &:not(.selected) {
        & > a {
          &:hover {
            color: $linkColor--hover;
          }
        }
      }
    }

    .mob-icon {
      top: 0.8em;
      left: 1em;
    }
  }

  .menu_level_2 {
    background: rgba(180, 180, 180,.9);
    display: none;
    position: static;
    overflow: hidden;
    opacity: 1;
    transition: all 0s;
    visibility: visible;
    will-change: transform;
    z-index: 5;

    & > li {
      display: block;
      padding: 0;

      & > a {
        padding: 0.5em 1em 0.6em 2em;
        color: $textColor;
        font-size: 13px;
      }
      &.selected {
        & > a {
          color: $white;
        }
        &.subcat {
          .menu_level_3 {
            display: block;
          }
        }
      }

      &:not(.selected) {
        & > a {
          &:hover {
          }
        }
      }
    }


    .mob-icon {
      display: none;
    }
  }

  .menu_level_3 {
    background: rgba(255,255,255,0.2);
    display: none;
    & > li {
      display: block;
      padding: 0;

      & > a {
        padding: 0.3em 1em 0.3em 3em;
        color: $textColor;
        font-size: 13px;
      }
      &.selected {
        & > a {
          color: $white;
        }
      }

      &:not(.selected) {
        & > a {
          &:hover {
          }
        }
      }
    }
  }
}
}



.mobile,
.tablet {

  .nav-left {
    padding: 0;
  }


  nav {
    .mail{
      text-align: center;
      .mail-wrapper{
        display: inline-block;
        position: relative;
        text-align: center;
        padding: 0.5em 0 0.5em 2em;
        .icon {
          @include fontawesome('\f0e0') {
            color: $textColor;
          }
        }
        .mail-link{
          color: $textColor;
          &:hover{
            color: $linkColor--hover;
          }
        }
      }
    }
    .call-order-wrapper {
      text-align: center;
      margin: 0 0 1em;
    }
  }
}


/* Левое меню */


.mobile,
.tablet {
  .nav-left {

    .menu_level_1 {
      display: block;

      & > li {
        position: relative;

        .mob-icon {
          margin-top: -7px;
          top: 19px;
          left: 0.5em;
          z-index: 1;
        }

        & > a {
          padding: 5px 1em 5px 2em;
          border-radius: $borderRadius;
        }
        &.selected{
          .mob-icon{
            color: $white !important;
          }
        }
      }
    }
  }
}

