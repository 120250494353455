// Базовые стили owl carousel
.owl-carousel {
	display: none;
	height: 100%;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	position: relative;
	visibility: hidden;
	z-index: 1;

	.owl-stage {
		position: relative;
		height: 100%;
		-ms-touch-action: pan-Y;
	}
	.owl-stage:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}
	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		height: 100%;
		-webkit-transform: translate3d(0, 0, 0);
	}

	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		height: 100%;
		width: 100%;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		will-change: transform;
	}

	.owl-nav .owl-prev,
	.owl-nav .owl-next,
	.owl-dot {
		cursor: pointer;
		cursor: hand;
		user-select: none;
	}

	.owl-nav {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		height: 0;

		&.disabled {
			display: none;
		}

		.owl-prev,
		.owl-next {
			color: #fff;
			cursor: pointer;
			font: normal 2em/1 "FontAwesome";
			font-weight: bold;
			opacity: 0.5;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			transition: all 0.3s ease;
			user-select: none;
			-webkit-tap-highlight-color: $webkitHighlightTapColor;
			&.disabled{
				opacity: 0.5 !important;
				cursor: default;
			}
		}

		.owl-prev {

			left: 0.3em;
			right: auto;

			&:hover {
				opacity: 0.9;
			}
		}

		.owl-next {
			left: auto;
			right: 0.3em;

			&:hover {
				opacity: 0.9;
			}
		}
	}

	.owl-dots {
		/*background: $headerTopBgColor;*/
		display: inline-block;
		padding: 0.21426em 1em 0;
		position: absolute;
		left: 50%;
		bottom: 1em;
		transform: translateX(-50%);
		z-index: 100;

		&.disabled {
			display: none;
		}


		@include media(md) {
			font-size: 12px;
		}

		& > .owl-dot {
			display: inline-block;
			zoom: 1;

			&.active {

				& > span {
					background: rgb(25,48,80);
					background: rgba(25,48,80,0.2);
					border: 0.35643em solid #fff;
					padding: 0.28568em;
					margin: 0.35711em 0.28568em;
					width: 1.18em;
					height: 1.18em;
				}
			}

			& > span {
				border: 0.2851em solid rgba(0,0,0,0);
				margin: 0.71427em 0.64283em;
				background: #fff;
				display: block;
				transition: opacity 200ms ease;
				border-radius: 2.142854em;

				@include media(md) {
					border: 0.25em solid rgba(0,0,0,0);
				}
			}
		}
	}

	.animated {
		animation-duration: 1000ms;
		animation-fill-mode: both;
	}

	.owl-animated-in {
		z-index: 0;
	}

	.owl-animated-out {
		z-index: 1;
	}

	.fadeOut {
		animation-name: fadeOut;
	}

	.owl-height {
		transition: height 500ms ease-in-out;
	}

	.owl-item .owl-lazy {
		opacity: 0;
		transition: opacity 400ms ease;
	}

	.owl-item img.owl-lazy {
		transform-style: preserve-3d;
	}


	&.owl-loaded {
		display: block;
		visibility: visible;
	}

	&.owl-loading {
		opacity: 0;
		display: block;
	}
	&.owl-hidden {
		opacity: 0;
	}
	&.owl-refresh .owl-item {
		display: none;
	}
	&.owl-drag .owl-item {
		user-select: none;
	}
	&.owl-grab {
		cursor: move;
		cursor: grab;
	}
	&.owl-rtl {
		direction: rtl;
	}
	&.owl-rtl .owl-item {
		float: right;
	}

}



// Стили слайдера на главной
.slider {
	.owl-stage-outer{
		/*border-radius: $borderRadius;*/
	}
	&.owl-carousel {
		.owl-item {
			/* От 1200px и ниже уменьшаем высоту в зависимости от ширины экрана */
			@include media(xlg) {

			}
		}

		.slider-item {
			position: relative;
			height: 100%;
			background: $white;
			&:after{
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: red;
				z-index: -1;
			}

			& > a,
			& > div {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				/*border-radius: $borderRadius;*/
			}
		}

		.item-label {
			background: #fff;
			background: rgba(255,255,255, 0.85);
			padding: 0.5em 1em;
			position: absolute;
			bottom: 20%;
			left: 10%;
			transition: background 0.3s ease;
			will-change: transform;

			& > .item-label__link {
				display: block;
				text-decoration: none;

				& > p {
					color: $textColor;
				}
			}

			&:hover {
				background: #eee;
				background: rgba(255,255,255,0.5);

				& > .item-label__link {
					color: $textColor;
				}
			}
		}
	}
}

.slider-block {
	margin: 0 auto 0;
	width: 100%;
	background: $white;
	border-left: 1em solid white;
	border-right: 1em solid white;
	padding: 1em 0 0;
}


.no-js {
	.owl-carousel {
		display: block;
	}
}



// Стили слайдера на мобильных
.mobile,
.tablet {

	.slider-block {
		margin: 0.5em auto 0;

		@include media(slg) {
			margin: 0;
		}
	}

	.slider {
		&.owl-carousel {
			background: none;
			padding: 0;
		}
	}
}


.module-inner {
	.owl-carousel {

		.owl-nav {
			.owl-prev,
			.owl-next {
				background: $moduleHeaderBg;
				border-radius: 50%;
				color: #fff;
				line-height: 1em;
				opacity: 1;
				height: 1em;
				width: 1em;
				text-align: center;
			}


			.owl-prev {
				left: -0.5em;
			}

			.owl-next {
				right: -0.5em;
			}


		}

		@include media(md) {

			.owl-nav {
				.owl-prev {
					left: 0;
				}

				.owl-next {
					right: 0;
				}
			}
		}
	}
}


.tablet,
.mobile {
	.module-inner {
		.owl-carousel {

			.owl-nav {
				.owl-prev,
				.owl-next {
					display: none;
				}
			}
		}
	}
}