/* Вертикальный градиент вверх */
@mixin gradient($startColor, $endColor, $gradiend) {

  @if($gradiend == false) {
    background: $startColor;
  }
  @else {
    background: $startColor;
    background: linear-gradient(to top, $startColor, $endColor);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$startColor,endColorstr=$endColor, GradientType=0 );
  }
}

@mixin button() {

  @if($isButtonGradient == true) {
    background: linear-gradient(to top, $buttonBgColor1, $buttonBgColor2);

    &:hover {
      background: linear-gradient(to top, $buttonBgColor1--hover, $buttonBgColor2--hover);
    }
  }
  @else {
    background: $buttonBgColor1;

    &:hover {
      background: $buttonBgColor1--hover;
    }
  }

  &:hover {
    border-color: $buttonBorderColor--hover;
    color: $buttonColor--hover;
    transition: all 0.3s ease;
    text-decoration: none;
  }

  &:active {
    box-shadow: inset 0 2px 7px 0 rgba(0,0,0,0.5);
    transition: all 0.2s ease;
    will-change: padding;
  }

  &.disabled {
    @include gradient(#aaa, #999, true);
    cursor: default;
    pointer-events: none;
  }

  border-radius: $borderRadius;

  @if($isButtonShadow) {
    box-shadow: $buttonShadow;
  }

  @if($isButtonBorder) {
    border: $buttonBorder;
  }
  @else {
    border: none;
  }

  @if($buttonBorderRadius != 0) {
    border-radius: $buttonBorderRadius;
  }

  /* Стили кнопки по-умолчанию */
  display: inline-block;
  color: $buttonColor;
  cursor: pointer;
  font-size: 15px;
  outline: none;
  padding: 7px 14px;
  pointer-events: all;
  text-decoration: none;
  transition: all 0.2s ease;
  user-select: none;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;
  -webkit-appearance: none;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;

  @content;
}

@mixin input() {

  background: $inputBg;

  @if($isInputBorder == true) {
    border: 1px solid $inputBorderColor;
  }

  border-radius: $borderRadius;
  color: $inputColor;
  display: block;
  font-size: 13px;
  padding: 3px 0.5em 3px;
  outline: none;

  @content;
}

@mixin module($isMobile) {
  margin: 1em 0 2em;
  border-radius: $borderRadius;
  overflow: hidden;

  @if($isMobile == false) {
    background: $moduleBg;
  }

  @if($moduleBorder != 0) {
    border: $moduleBorder;
  }

  .module-header {

    @if($isMobile) {
      background: $moduleMobileHeaderBg;
    }
    @else {
      background: $moduleHeaderBg;
    }

    padding: .3em .5em;
    margin: 0 0 1px;

    & > span {

      @if($isMobile) {
        color: $moduleMobileHeaderColor;
      }
      @else {
        color: $moduleHeaderColor;
      }

      cursor: default;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }

    text-align: center;
  }

  @content;
}

@mixin navtop() {


  @if($isTopMenuGradient == true) {
    background: linear-gradient(to top, $topMenuBgColor1, $topMenuBgColor2);

    .menu_level_1 > .menu-item.selected {
      background: linear-gradient(to top, $topMenuBgColor1--selected, $topMenuBgColor2--selected);
    }
  }
  @else {
    background: $topMenuBgColor1;

    .menu_level_1 > .menu-item.selected {
      background: $topMenuBgColor1--selected;
    }
  }

  @if($isTopMenuShadow) {
    box-shadow: $topMenuShadow;
  }
  @if($isTopMenuBorder) {
    border: $topMenuBorder;
  }
  @if($isTopMenuBorderRounded) {
    border-radius: $topMenuBorderRadius;
  }

  @content;
}


@mixin fontawesome($n) {

  &:before {
    @if($n != '') {
      content: $n;
    }
    @else {
      content: '';
    }

    color: $linkColor;
    font-family: "FontAwesome";
    font-size: 18px;
    position: absolute;
    margin: -11px 0 0 -10px;
    top: 50%;
    left: 50%;

    @content;

    z-index: 2;
  }
}




@mixin sideBorder($side: 'left') {
  &:after {
    background: $headerTopBgColorMobile * 1.2;
    border-right: 1px solid $headerTopBgColorMobile * 0.8;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    #{$side}: 0;
    width: 1px;

    @content;
  }
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}

.img-responsive {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
}

