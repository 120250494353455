.module {
	@include module(false);
	.filter-wrapper,
	.vote-block{
		padding: 1em;
	}
	.news-block{
		padding: 1em 1em 0;
	}
}
/* Оформление модулей в контент области */
.module-inner {
	clear: both;
	margin: 1em 0 2em;
	& > .module-header {
		background: none;
		padding: .3em .5em;
		margin: 0 0 .5em;
		text-align: center;

		& > span {
			font-size: 16px;
			font-weight: 700;
			color: $black;
			text-transform: uppercase;
		}
	}
}
.module-wrapper {
	position: relative;
}
/* Бренды */
.module.brands {

	.brands-list {
		display: block;
		list-style: none;
		padding: 1em;
	}

	.brands-list__item {
		font-size: 1.14286em;
		line-height: 1.9;

		& > span {
			color: $linkColor--hover;
		}
	}

	.brands-list__link {
		color: $linkColor;
		transition: color 0.3s ease;

		&:hover {
			color: $linkColor--hover;
		}
	}
}
.module.brands {
	@include media(md) {
		float: left;
		padding: 0 0.5em 0 1em;
		width: 50%;
	}
	@include media(sm) {
		float: none;
		padding: 0;
		width: 100%;
	}
}
.mobile,
.tablet {
	.module,
	.module-inner {
		background: none;
		margin: 1em 0 0;

		& > .module-header {
			background: $moduleMobileHeaderBg;
			border-radius: $borderRadius;

			&.toggle{
				position: relative;
				@include fontawesome('\f078') {
					font-size: 14px;
					color: $white;
					left: inherit;
					right: 1em;
					margin: -10px 0 0;
				}
				&.active{
					&:before{
						content: '\f077';
					}
				}
			}

			& > span {
				color: $moduleMobileHeaderColor;
				& > a {
					text-decoration: none;
					color: $moduleMobileHeaderColor;
				}

			}

			@include media(md) {
				padding: 0.5em 1em;

				& > span {
					font-size: 16px;
				}
			}
		}

		.module-wrapper {
			padding: 0;
			margin: 0 0 1em;
		}
		.module-header{
			margin: 0 0 1em;
		}
	}
	.main-left-menu{
		margin: 0 0 1em;
		.module-header{
			margin: 0;
		}
		.module-wrapper {
			@include media(md) {
				display: none;
			}
		}
	}
}

