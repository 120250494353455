.container {
  margin: 0 auto;
  max-width: $containerWidth;
  width: 100%;

  @extend .clearfix;
}


main {
  width: 100%;
  > .container{
    background: $white;
  }
}

aside {
  float: left;
  padding: 0 1em;
  width: 270px;

  @include media(lg) {
    float: none;
    width: 100%;
  }
}

.content-block {
  float: right;
  padding: 0 1em;
  width: calc(100% - 270px);
  min-height:calc(100vh - 104px - 49px - 410px - 301px);

  @include media(lg) {
    float: none;
    width: 100%;
  }
}
